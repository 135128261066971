.button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 40px;
}

.span{
  margin-left: 4px;
  font-family: var(--font-ttsupermolotneue);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
}

.default {
  @extend .button;
  background: #191f40;
  min-width: 48px;
  width: 66px;
  height: 44px;
  color: #939bc2;
  border: none;
  border-radius: 8px;
  
  svg {
    path {
      fill: #939bc2;
    }
    max-width: 16px;
    max-height: 16px;
    min-width: 16px;
    min-height: 16px;
  }
  &:disabled {
    cursor: auto;
    svg {
      path {
        opacity: 0.48;
      }  
    }
  }

  &:hover:not(:disabled) {
    border: none;
    background: #191f40;
    svg {
      path {
        fill: #bdc2dc;
      }
    }
    .span{
      color: #bdc2dc;
    }
  }
}

.active {
  background: #252e5c;
  border: 2px solid rgba(25, 31, 64, 1);
  svg {
    path {
      fill: #d3f85a;
    }
    filter: drop-shadow(0px 0px 24px rgba(211, 248, 90, 0.42));
  }
  .span{
    color: #d3f85a;
  }
  &:hover:not(:disabled) {
    border: none;
    background: #252e5c;
    svg {
      path {
        fill: #d3f85a;
      }
    }
    .span{
      color: #d3f85a;
    }
  }
}