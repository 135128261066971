.container_date{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    border-radius: 10px;
    .container_date_info{
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      justify-content: space-between;
      margin-left: 6px;
      .timer_text{
        color: rgb(255, 255, 255);
        font-family: var(--font-ttsupermolotneue);
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0%;
        text-align: left;
        text-transform: uppercase;
      }  
    }
    .date_circle{
      min-width: 16px!important; 
      min-height: 16px!important;
      height: 16px!important;
      width: 16px!important; 
      
    }
  }