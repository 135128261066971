.button {
  display: flex;
  // position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 4px 4px 12px;
  gap: 8px;
  border-radius: 120px;
  height: 48px;
}
.text {
  font-family: var(--font-ttsupermolotneue);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-right: 8px;
  white-space: nowrap;
}

.default,
.dark {
  @extend .button;
  background: #d3f85a;
  color: #0c1027;

  box-shadow: 0 0 0 4px rgba(211, 248, 90, 0.16);
  border: none;

  .button_text {
    @extend .text;
    color: #0c1027;
  }

  .oval_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 40px;
    height: 40px;
    background: #0c1027;
    border-radius: 40px;
    svg {
      path {
        stroke: #d3f85a;
      }
    }
  }

  svg {
    path {
      stroke: #0c1027;
    }
  }

  &:disabled {
    opacity: 0.56;
    cursor: auto;
  }

  &:hover:not(:disabled) {
    background: #dcf97b;

    box-shadow: 0 0 0 4px rgba(211, 248, 90, 0.32);
    border: none;
  }
}

.dark {
  background: #191f40;
  color: #d3f85a;
  box-shadow: none;

  .button_text {
    color: #d3f85a;
  }

  .oval_container {
    background: #d3f85a;
    svg {
      path {
        stroke: #0c1027;
      }
    }
  }

  svg {
    path {
      stroke: #d3f85a;
    }
  }

  &:disabled {
    opacity: 1;
    background: #191f40;
    svg {
      path {
        stroke: #939bc2;
      }
    }
    .button_text {
      color: #939bc2;
    }
    .oval_container {
      background: #252e5c;
      svg {
        path {
          stroke: #939bc2;
        }
      }
    }
  }

  &:hover:not(:disabled) {
    box-shadow: none;
    background: #252e5c;
  }
}

// .container_primary,
// .container_dark {
//   width: max-content;
//   .default {
//     @extend .button;
//     background: #191f40;
//     border: none;
//     color: #d3f85a;

//     .button_text {
//       @extend .text;
//       color: #d3f85a;
//     }

//     .oval_container {
//       display: flex;
//       flex-direction: row;
//       justify-content: center;
//       align-items: center;
//       padding: 8px;
//       width: 40px;
//       height: 40px;
//       background: #d3f85a;
//       border-radius: 40px;
//       svg {
//         path {
//           stroke: #0c1027;
//         }
//       }
//     }

//     svg {
//       path {
//         stroke: #d3f85a;
//       }
//     }

//     &:disabled {
//       background: #191f40;
//       cursor: auto;
//       svg {
//         path {
//           stroke: #939bc2;
//         }
//       }
//       .button_text {
//         color: #939bc2;
//       }
//       .oval_container {
//         background: #252e5c;
//         svg {
//           path {
//             stroke: #939bc2;
//           }
//         }
//       }
//     }

//     &:hover:not(:disabled) {
//       background: #252e5c;
//       border: none;
//     }
//   }
// }

// .container_primary {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   height: 56px;
//   padding: 4px;
//   border-radius: 120px;
//   background: rgba(211, 248, 90, 0.16);
//   &:hover {
//     background: rgba(211, 248, 90, 0.32);
//   }
//   .default {
//     background: #d3f85a;
//     color: #0c1027;

//     .button_text {
//       color: #0c1027;
//     }

//     .oval_container {
//       background: #0c1027;
//       svg {
//         path {
//           stroke: #d3f85a;
//         }
//       }
//     }

//     svg {
//       path {
//         stroke: #0c1027;
//       }
//     }

//     &:disabled {
//       opacity: 0.56;
//       background: #d3f85a;
//       cursor: auto;
//       svg {
//         path {
//           stroke: #0c1027;
//         }
//       }
//       .button_text {
//         color: #0c1027;
//       }
//       .oval_container {
//         background: #0c1027;
//         svg {
//           path {
//             stroke: #d3f85a;
//           }
//         }
//       }
//     }

//     &:hover:not(:disabled) {
//       background: #dcf97b;
//       border: none;
//     }
//   }
// }

// .container_primary_disabled {
//   @extend .container_primary;
//   // opacity: 0.56;
//   background: rgba(211, 248, 90, 0.16);
//   &:hover:not(:disabled) {
//     background: rgba(211, 248, 90, 0.16);
//     border: none;
//   }
// }
