.container {
  display: flex;
  position: absolute;
  max-width: 240px;
  width: max-content;
  top: 10px;
  left: -8px;
  z-index: 2;
  .text, .textTimer {
    display: flex;
    align-items: center;
    padding: 4px 2px;
    gap: 4px;
    p {
      color: #fff;
      text-shadow: 0px 0px 20px rgba(132, 255, 120, 0.4);
      font-family: var(--font-play);
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      @media (max-width: 700px) {
        font-size: 9px;
      }
    }
  }
  .textTimer{
    
  }
}
