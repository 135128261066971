.container {
  background-color: #0c1027;
  position: absolute;
  padding: 16px 8px;
  max-width: 528px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 320px;
  border-radius: 10px;
  background: #191f40;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.4);
  .main {
    display: flex;
    gap: 16px;
    flex-direction: column;
    width: 100%;
  }
  .menuButton {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .linkCon {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .menuButtonLink {
    border-radius: 16px;
    background: linear-gradient(100deg, #336 0.51%, #1a1a33 99.72%);
    display: flex;
    height: 78px;
    overflow: hidden;
    position: relative;
    button {
      border: none;
      padding: 20px 0px 20px 16px;
    }
    .imageCon {
      width: 25%;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .userWrap {
    padding: 20px 16px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: #242a4a;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  .user_container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 12px;
    align-items: center;

    .containerCoin {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #d3f85a;
      gap: 8px;
      padding: 4px 12px;
      background-image: url('../../../assets/images/wheel/wheelInfo/green/left.svg'),
        url('../../../assets/images/wheel/wheelInfo/green/right.svg'),
        url('../../../assets/images/wheel/wheelInfo/green/left_down.svg'),
        url('../../../assets/images/wheel/wheelInfo/green/right_down.svg');
      background-position:
        left top,
        right top,
        left bottom,
        right bottom;
      background-size: 7px;
      max-height: 35px;
      background-repeat: no-repeat;
      @media (max-width: 700px) {
        span {
          width: 16px !important;
          height: 16px !important;
        }
      }
    }
    .user_info,
    .hd_coin_container {
      display: flex;
      flex-direction: row;
      border-radius: 8px;
      align-items: center;
      gap: 12px;
      padding: 4px 14px 4px 4px;
      overflow: hidden;
      cursor: pointer;

      .avatarBack_container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #191f40;
        border-radius: 40px;
        height: 60px;
        min-width: 60px;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        transition: transform 0.3s ease;
      }

      .avatarBack_container:hover {
        transform: scale(1.05);
      }

      .name {
        font-family: var(--font-ttsupermolotneue);
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .hd_coin_container {
      justify-content: center;
      padding: 8px 12px;
      user-select: none;

      .coin {
        font-family: var(--font-arame);
        font-size: 16px;
        line-height: 24px;
        color: var(--primary-color);
      }
    }
  }

  .link,
  .link_logout {
    width: 100%;
    justify-content: space-between;
    background: none;
    padding: 12px;
    gap: 8px;
    .bonuses {
      position: relative;
      padding: 4px 12px;
      border-radius: 5px;
      align-items: center;
      display: flex;
      justify-content: center;
      background-image: url('../../../assets/images/wheel/wheelInfo/green/left.svg'),
        url('../../../assets/images/wheel/wheelInfo/green/right.svg'),
        url('../../../assets/images/wheel/wheelInfo/green/left_down.svg'),
        url('../../../assets/images/wheel/wheelInfo/green/right_down.svg');
      background-position:
        left top,
        right top,
        left bottom,
        right bottom;
      background-size: 7px;
      background-repeat: no-repeat;
      .text_bonus {
        color: #d3f85a;
        text-shadow: 0px 0px 24px rgba(211, 248, 90, 0.42);
        font-family: var(--font-arame);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
  .link_logout {
    justify-content: flex-start;
  }
  .linkCon button p {
    color: #fff;
    font-family: var(--font-ttsupermolotneue);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
  }

  .mediaLinks {
    display: flex;
    width: 100%;
    gap: 8px;
    padding: 4px;

    a {
      background: #191f40;
      img {
        fill: #9297b2;
      }
    }
  }
}

.container_link {
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 8px;
  cursor: pointer;
  align-items: flex-start;
  width: 50%;
  transition: color 0.15s ease;
  .bonusContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.text {
  color: #fff;
  font-family: var(--font-arame);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 18px */
}

.barabanText {
  display: flex;
  p {
    color: #eee;
    text-shadow: 0px 0px 24px rgba(211, 248, 90, 0.42);
    font-family: var(--font-arame);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}
.textInfo {
  font-family: var(--font-tthoves);
  font-size: 12px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  color: #d6d6df;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  line-height: 160%;
  text-align: left;
}

.otherButton {
  padding: 12px;
  width: 100%;
  border-radius: 12px;
  color: #fff;
  background: #191f40;
  justify-content: flex-start;
  gap: 8px;
  &:hover {
    border: 2px solid #d3f85a;
  }
}
.promoBlockCon {
  display: flex;
  width: 100%;
  justify-content: center;
  background: #191f40;
  border-radius: 30px;
}

.bonusContainerMenu {
  display: flex;
  gap: 4px;
  div {
    display: flex;
  }
}
.bonusCon {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  width: max-content;
  border-radius: 10px;
  background: #3c4881;
}

.onlineUserCount {
  padding: 10px 16px;
  border-radius: 16px;
  display: flex;
  gap: 6px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  .imageContainer {
    position: relative;

    .image2 {
      position: absolute;
      width: 100%;
      height: 100%;
      top: -1px;
      left: 1px;
      transition: opacity 1s ease-in-out;
      opacity: 0;
      &.active {
        opacity: 1;
      }
    }
  }
  .countTxt {
    color: #fff;
    text-align: right;
    font-family: var(--font-tektur);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
