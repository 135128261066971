.loader {
  align-self: center;
}

.spinner {
  animation: spin 1s infinite linear;
  transform-origin: 92px 92px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bullet1 {
  animation: shot1 1.8s infinite cubic-bezier(0.44, 0.13, 0, 0.48);
  opacity: 1;
  transform: scale(0.75);
  transform-origin: 28px 28px;
}

.bullet2 {
  animation: shot2 1.8s infinite cubic-bezier(0.44, 0.13, 0, 0.48);
  opacity: 0;
  transform: scale(0.75);
  transform-origin: 28px 28px;
}

.bullet3 {
  animation: shot3 1.8s infinite cubic-bezier(0.44, 0.13, 0, 0.48);
  opacity: 0;
  transform: scale(0.75);
  transform-origin: 28px 28px;
}

@keyframes shot1 {
  0% {
    opacity: 1;
    transform: scale(0.8);
  }
  1% {
    opacity: 1;
  }
  5% {
    opacity: 1;
    transform: scale(1);
  }
  23% {
    opacity: 1;
    transform: scale(1);
  }
  33% {
    opacity: 0;
    transform: scale(0.75);
  }
  99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: scale(0.75);
  }
}

@keyframes shot2 {
  32% {
    opacity: 0;
  }
  33% {
    opacity: 1;
    transform: scale(0.75);
  }
  34% {
    opacity: 1;
  }
  38% {
    opacity: 1;
    transform: scale(1);
  }
  56% {
    opacity: 1;
    transform: scale(1);
  }
  66% {
    opacity: 0;
    transform: scale(0.75);
  }
}
@keyframes shot3 {
  65% {
    opacity: 0;
  }
  66% {
    opacity: 1;
    transform: scale(0.75);
  }
  67% {
    opacity: 1;
  }
  71% {
    opacity: 1;
    transform: scale(1);
  }
  90% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.75);
  }
}
