/* Absolute Center Spinner */
.loading {
  position: fixed;
  margin: 0;
  padding: 0;
  background-color: #0c1027;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
}
