.money_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--primary-color);
  padding: 0 6px 0 18px;
  border-radius: 8px;
  gap: 20px;
  height: 48px;
  justify-content: space-between;
  cursor: pointer;
  .iconAndAmount {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .money {
    color: #192740;
    font-weight: 400;
    font-size: 16px;
    font-family: var(--font-arame);
    white-space: nowrap;
  }
}
