.modal_first_dep {
  position: fixed;
  bottom: 16px;
  left: 16px;
  z-index: 1000;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  overflow: hidden;
  animation: slide-in 0.3s ease-out;
}

/* Анимация появления */
@keyframes slide-in {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.modalContent {
  position: relative;
  padding: 32px 20px;
  width: 420px;
  color: #fff;
  text-align: center;
  border-radius: 16px;
  background: linear-gradient(237deg, #5a62f8 0%, #7f5af8 100%);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  &.small {
    width: 335px;
    padding: 4px 12px 4px 4px;
    cursor: pointer;
    .lightning {
      position: absolute;
      z-index: 1;
      &:nth-of-type(2) {
        top: 0;
        animation: moveSquare 0.7s infinite;
      }
      &:nth-of-type(3) {
        top: 10%;
        left: 20%;
        animation: moveSquare2 0.7s infinite;
      }
      &:nth-of-type(4) {
        top: 70%;
        left: 7%;
        animation: moveSquare3 0.7s infinite;
      }
    }
    @media screen and (width < 768px) {
      width: 214px;
    }
  }

  @media screen and (width < 768px) {
    width: 335px;
  }
}
.closeButton {
  position: absolute;
  top: 2%;
  right: 2%;
  padding: 4px;
  border-radius: 24px;
  background: rgba(147, 155, 194, 0.5);
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.modalTitle {
  color: #fff;
  text-align: center;
  font-family: var(--font-tektur);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 116.667% */

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 23px;
  }
}

.modalSubtitle {
  color: #fff;
  text-align: center;
  font-family: var(--font-play);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }
}

.promoContainer {
  margin: 32px 0;
  position: relative;
  z-index: 2;

  .badge {
    position: absolute;
    right: 53%;
    top: -50%;
    @media (max-width: 700px) {
      right: 45%;
    }
  }

  .lightning {
    position: absolute;
    z-index: 1;
    svg {
      width: 16px;
      height: 16px;
    }
    &:nth-of-type(3) {
      top: 30%;
      left: 2%;
      animation: moveSquare 0.7s infinite;
    }
    &:nth-of-type(4) {
      top: -40%;
      left: 20%;
      animation: moveSquare2 0.7s infinite;
    }
    &:nth-of-type(5) {
      top: 105%;
      left: 17%;
      animation: moveSquare3 0.7s infinite;
    }
  }
}

.promoCode {
  display: inline-block;
  border-radius: 12px;
  border: 1px dashed #d3f85a;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  padding: 12px 16px;
  width: 300px;

  p {
    color: #fff;
    text-align: center;
    text-shadow: 0 0 4px rgba(211, 248, 90, 0.4);
    font-family: var(--font-inter);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 100% */
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    width: 238px;

    p {
      font-size: 20px;
      line-height: 20px;
    }
  }
}

.textModal {
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 2;
  position: relative;
}

.promoBadge {
  display: block;
  font-size: 12px;
  color: #ff5e3a;
  margin-bottom: 4px;
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  position: relative;

  button {
    max-width: 250px;
  }
}

.img1 {
  position: absolute;
  top: 17%;
  left: 0;
}

.img2 {
  position: absolute;
  bottom: -2%;
}

.circularProgress {
  position: absolute;
  width: 80px;
  height: 80px;
  z-index: 1;
  top: -30%;
  left: 5%;
  &.small {
    width: 46px;
    height: 46px;
    position: relative;
    left: 0;
  }
  .wrapper {
    position: relative;
    overflow: hidden;

    svg,
    span,
    div {
      max-width: 80px !important;
      max-height: 80px !important;
    }

    .bg {
      position: absolute;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background: #5c4ae4;
      top: 10%;
      left: 10%;
    }
  }

  @media (max-width: 768px) {
    width: 64px;
    height: 64px;
    top: -20%;
    left: 1%;

    .wrapper {
      svg,
      span,
      div {
        max-width: 64px !important;
        max-height: 64px !important;
      }

      .bg {
        width: 50px;
        height: 50px;
        top: 13%;
        left: 13%;
      }
    }
  }
}

.ellipseBig,
.ellipseMini {
  position: absolute;
}

.ellipseBig {
  left: 1%;
  top: 28%;
  @media (max-width: 700px) {
    left: -6%;
  }
}

.ellipseMini {
  left: 7%;
  top: 37%;
  z-index: 2;
  @media (max-width: 700px) {
    left: 0;
  }
}

.ellipseTop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.imgBottom {
  position: absolute;
  right: 0;
  z-index: 3;
  bottom: -3%;
}

.wrapp {
  display: flex;
  flex-direction: row;
  gap: 8px;
  .giftInfo {
    display: flex;
    align-items: center;
    gap: 6px;
    p {
      color: #fff;
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.5);
      font-family: var(--font-play);
      font-size: 12.571px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}

@keyframes moveSquare {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(20%, 0);
  }
  50% {
    transform: translate(0, 20%);
  }
  75% {
    transform: translate(20%, 20%);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes moveSquare2 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(20%, 20%);
  }
  50% {
    transform: translate(0, 20%);
  }
  75% {
    transform: translate(20%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes moveSquare3 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(20%, 20%);
  }
  50% {
    transform: translate(20%, 0);
  }
  75% {
    transform: translate(0, 20%);
  }
  100% {
    transform: translate(0, 0);
  }
}
