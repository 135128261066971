@use '../../../styles/utils' as util;

.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-inline: 2rem;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 1100px) {
    padding-right: 0;
    padding-left: 0;
  }

  .main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    width: 100%;
    height: 100%;
  }
}

.embla {
  position: relative;
  --slide-spacing: 0.75rem;
  --slide-size: 85%;
  --slide-height: 19rem;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    // left: -17px;
    top: 0;
    height: 100%;
    z-index: 1;
    box-shadow: 3px 0 15px 10px rgb(12 16 39);
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    box-shadow: -3px 0 15px 10px rgb(12 16 39);
  }
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  width: auto;
  max-width: 430px;
  padding-left: var(--slide-spacing);
  position: relative;
}

.bigCards {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .bigCards {
        gap: 0.5rem;
      }
    }
  }

  .div1 {
    grid-area: 1 / 1 / 3 / 4;

    background: -webkit-linear-gradient(
      to right,
      #28316c,
      #2a3271,
      #293b7b,
      #2a3c7c,
      #2a4783,
      #2a4a83,
      #2a5588,
      #27628c,
      #266a8d,
      #26738f,
      #257691,
      #258696,
      #26879a,
      #2a80a3
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #28316c,
      #2a3271,
      #293b7b,
      #2a3c7c,
      #2a4783,
      #2a4a83,
      #2a5588,
      #27628c,
      #266a8d,
      #26738f,
      #257691,
      #258696,
      #26879a,
      #2a80a3
    );

    justify-content: flex-start;

    .cardContainer {
      background-image: url('../../../assets/images/mainBackground/mainPageCards/discord@2x.png');
      background-repeat: no-repeat;
      background-position: right -55px center;
      background-size: 280px 280px;
      width: 100%;
      cursor: auto;

      @media (max-width: 1650px) {
        background-size: 250px;
      }

      @media (max-width: 1230px) {
        background-position-y: -25px;
        background-position-x: 140px;
        @media (max-width: 1100px) {
          background-position: center -35px;
          padding: 24px 16px;
          background-size: 55%;
        }
      }

      @media (max-width: 1230px) {
        background-position: 150px -10px;
        background-size: 145px;
        background-position-x: 140px;

        @media (max-width: 1100px) {
          background-position: center -15px;
          padding: 24px 16px;
          background-size: 50%;
        }
      }

      .cardInfo {
        width: 65%;
        justify-content: end;
        padding-left: 1.5rem;
        padding-bottom: 1.5rem;
        @media (max-width: 1475px) {
          //padding-left: 1rem;
          //padding-bottom: 1.5rem;
          //width: 70%;
          @media (max-width: 1230px) {
            width: 100%;
            h2 {
              width: 60%;
            }
          }
          @media (max-width: 1100px) {
            padding: 0;
            align-items: center;
            h2 {
              width: 100%;
            }
          }
        }

        .cardText {
          gap: 0.5rem;
          display: flex;
          flex-direction: column;
          @media (max-width: 1100px) {
            h2 {
              text-align: center;
            }
            p {
              text-align: center;
            }
          }
        }
      }
    }

    .imgContainer {
      width: 183px;
      display: flex;
      @media (max-width: 1440px) {
        right: 0;
        top: -25px;
        width: 45%;
      }
    }
  }

  .div2 {
    grid-area: 1 / 10 / 3 / 13;
    padding-top: 10px;
    overflow: hidden;
    width: 100%;
    position: relative;

    .cardContainer {
      background-image: url('../../../assets/images/mainBackground/mainPageCards/newWheel.webp');
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: contain;
      width: 100%;
      @media (min-resolution: 2dppx) {
        & {
          background-image: url('../../../assets/images/mainBackground/mainPageCards/newWheel2x.webp');
        }
      }
      @media (max-width: 1440px) {
        background-position-x: 200px;
        @media (max-width: 1200px) {
          background-position-x: 150px;
        }
      }

      .cardInfo {
        justify-content: flex-end;
        padding: 16px 0 16px 24px;
        @media (max-width: 1390px) {
          padding: 16px 0 16px 16px;
        }

        .cardText {
          height: 100%;
          @media (max-width: 1200px) {
            p {
              width: 70%;
            }
          }
        }

        .cardHeaderArrow {
          height: 100%;
          display: flex;
          flex-direction: column-reverse;
          align-items: flex-start;
          justify-content: space-between;
        }
      }
    }

    .imgContainer {
      display: flex;
      @media (max-width: 1440px) {
        position: absolute;
        right: -95px;
        bottom: 0;
      }
    }
  }

  .div3 {
    grid-area: 1 / 4 / 2 / 8;
    overflow: hidden;

    .cardContainer {
      padding: 16px 0 16px 24px;
      background-image: url('../../../assets/images/mainBackground/mainPageCards/newHDCoin.webp');
      background-position: right bottom;
      background-repeat: no-repeat;
      width: 100%;
      @media (min-resolution: 2dppx) {
        & {
          background-image: url('../../../assets/images/mainBackground/mainPageCards/newHDCoin2x.webp');
          background-size: 170px;
        }
      }

      .cardInfo {
        display: flex;
        justify-content: space-between;
      }
    }

    .imgContainer {
      width: 183px;
      display: flex;
      align-items: center;
      @media (max-width: 1390px) {
        bottom: -25px;
        right: -20px;
        width: 50%;
      }
    }

    .cardText {
      @media (max-width: 1390px) {
        p {
          width: 60%;
        }
      }
    }
  }

  .div4 {
    grid-area: 1 / 8 / 2 / 10;
    width: 100%;

    .cardContainer {
      width: 100%;
      background-image: url('../../../assets/images/mainBackground/mainPageCards/newUpgrade.webp');
      background-position: right bottom;
      background-repeat: no-repeat;
      @media (min-resolution: 2dppx) {
        & {
          background-image: url('../../../assets/images/mainBackground/mainPageCards/newUpgrade2x.webp');
          background-size: 130px;
        }
      }
      @media (max-width: 1390px) {
        background-position-x: 100px;
        @media (max-width: 1300px) {
          background-position-x: 80px;
        }
      }

      .cardInfo {
        padding: 16px 0 16px 24px;
        @media (max-width: 1390px) {
          padding: 16px 0 16px 16px;
        }
      }
    }

    .imgContainer {
      display: flex;
      right: 0;
      bottom: 0;
      @media (max-width: 1390px) {
        width: 50%;
      }
    }
  }

  .div5 {
    grid-area: 2 / 7 / 3 / 10;
    overflow: hidden;
    background: -webkit-linear-gradient(
      90deg,
      #2c2c58,
      #2c2c58,
      #482c69
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      90deg,
      #2c2c58,
      #2c2c58,
      #482c69
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    .cardContainer {
      width: 100%;
      background-image: url('../../../assets/images/mainBackground/mainPageCards/store.webp');
      background-position: right bottom;
      background-repeat: no-repeat;
      @media (min-resolution: 2dppx) {
        & {
          background-image: url('../../../assets/images/mainBackground/mainPageCards/store2x.webp');
          background-size: 100px;
        }
      }

      .cardInfo {
        padding: 16px 0 16px 24px;
        justify-content: space-between;
        @media (max-width: 1440px) {
          padding: 16px 0 16px 16px;
          @media (max-width: 1390px) {
            .cardText {
              p {
                width: 60%;
              }
            }
          }
        }
      }
    }

    .imgContainer {
      bottom: 0;
      right: 0;
      display: flex;
      @media (max-width: 1440px) {
        right: -25px;
        @media (max-width: 1390px) {
          right: -50px;
        }
      }
    }
  }

  .div6 {
    grid-area: 2 / 4 / 3 / 7;
    overflow: hidden;

    .cardContainer {
      align-items: flex-start;
      width: 100%;
      background-image: url('../../../assets/images/mainBackground/mainPageCards/newGiveaway.webp');
      background-position: right bottom;
      background-repeat: no-repeat;
      @media (min-resolution: 2dppx) {
        & {
          background-image: url('../../../assets/images/mainBackground/mainPageCards/newGiveaway2x.webp');
          background-size: 120px;
        }
      }
      @media (max-width: 1400px) {
        background-position: right bottom;
        @media (max-width: 1300px) {
          background-position-x: 180px;
          @media (max-width: 1200px) {
            background-position-x: 150px;
          }
        }
      }

      .cardInfo {
        padding: 16px 0 16px 24px;
        justify-content: space-between;
        @media (max-width: 1390px) {
          padding: 16px 0 16px 16px;
        }
      }
    }

    .imgContainer {
      @media (max-width: 1390px) {
        width: 45%;
        bottom: 0;
        right: -25px;
        display: flex;
      }
    }

    .bonusCon {
      P {
        width: 100%;
      }

      div {
        padding: 0;
        background: none;
        gap: 0.25rem;
        width: max-content;

        span,
        svg {
          width: 16px !important;
          height: 16px !important;
          min-width: 16px !important;
          min-height: 16px !important;
        }

        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        div {
          margin-left: 4px;

          p:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }

  .div6,
  .div3,
  .div4,
  .div5 {
    height: 131px;
  }

  .div1,
  .div2 {
    height: 272px;
  }

  .div1,
  .div2,
  .div3,
  .div4,
  .div5,
  .div6 {
    border-radius: 1rem;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    overflow: hidden;

    h2 {
      color: #fff;
      font-family: var(--font-arame);
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      @media (max-width: 1390px) {
        font-size: 18px;
        line-height: 130%;
      }
    }
  }
}

.div2,
.div3,
.div4,
.div5,
.div6 {
  background: -webkit-linear-gradient(
    90deg,
    #303060,
    #28274e,
    #452962
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    90deg,
    #303060,
    #28274e,
    #452962
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-position-x: center;
}

.div3,
.div4,
.div5 {
  .cardContainer {
    align-items: flex-start;
  }
}

.cardInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;

  .cardHeaderArrow {
    display: flex;
    gap: 0.75rem;
    align-items: center;

    button {
      padding: 0.25rem;
      min-width: 24px;
      width: 24px;
      height: 24px;

      svg {
        width: 10px;

        & path {
          stroke: #9297b2;
        }
      }
    }
  }
}

.cardButton {
  margin-top: 0.938rem;
  width: 80%;
}

.cardButtonText {
  font-family: var(--font-play);
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: center;
}

.bonusCon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem;
  width: max-content;
  border-radius: 10px;
  background: #3c4881;
  min-height: 38px;
  color: #eee;
  font-family: var(--font-tthoves);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.imgContainer {
  overflow: hidden;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  cursor: pointer;
}

.smallCards {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  gap: 12px;

  .secondCon {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 0.75rem;
    overflow: hidden;

    .cardCon2 {
      display: flex;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      overflow: hidden;
      background: linear-gradient(100deg, #336 0.51%, #1a1a33 99.72%);
      padding-top: 10px;
      cursor: pointer;

      .cardInfo2 {
        display: flex;
        flex-direction: column;

        h2 {
          color: #fff;
          font-family: var(--font-arame);
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
        }

        p {
          color: #d6d6df;
          text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
          font-family: var(--font-tthoves);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
        }

        .text_bonus {
          color: #d3f85a;
          text-shadow: 0 0 24px rgba(211, 248, 90, 0.42);
          font-family: var(--font-arame);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          width: auto;
        }
      }
    }
  }
}

.bonuses {
  padding: 4px 12px;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  .text_bonus {
    display: flex;
    gap: 4px;
    align-items: center;
    white-space: nowrap;
    color: #d3f85a;
    text-shadow: 0 0 24px rgba(211, 248, 90, 0.42);
    font-family: var(--font-arame);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.bonusCon2 {
  display: flex;
  gap: 8px;
  align-items: center;
}

.secondDiv2 {
  background-image: url('../../../assets/images/menu/newWheel.webp');
}

.secondDiv3 {
  background-image: url('../../../assets/images/menu/newHDCoin.webp');
}

.secondDiv4 {
  background-image: url('../../../assets/images/menu/newUpgrade.webp');
}

.secondDiv5 {
  background-image: url('../../../assets/images/menu/store.webp');
}

.secondDiv6 {
  background-image: url('../../../assets/images/menu/newGiveaway.webp');
}

.secondDiv2,
.secondDiv3,
.secondDiv4,
.secondDiv5,
.secondDiv6 {
  width: 100%;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  padding: 10px 20px 20px 20px;
}

.barabanCon {
  display: flex;
  gap: 4px;

  .barabanText {
    display: flex;

    p {
      color: #eee;
      text-shadow: 0 0 24px rgba(211, 248, 90, 0.42);
      font-family: var(--font-arame);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.bonuses {
  background-image: url('../../../assets/images/wheel/wheelInfo/green/left.svg'),
    url('../../../assets/images/wheel/wheelInfo/green/right.svg'),
    url('../../../assets/images/wheel/wheelInfo/green/left_down.svg'),
    url('../../../assets/images/wheel/wheelInfo/green/right_down.svg');
  background-position:
    left top,
    right top,
    left bottom,
    right bottom;
  background-size: 7px;
  background-repeat: no-repeat;
}

.cardInfo2Item {
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #3c4881;
  padding: 2px 4px;

  span {
    height: 16px !important;
    width: 12px !important;
  }

  div {
    display: flex;
  }

  .barabanText {
    p {
      color: #eee;
      text-shadow: 0 0 24px rgba(211, 248, 90, 0.42);
      font-family: var(--font-arame);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.cardText {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .firstBannerTxt {
    color: #d6d6df;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    font-family: var(--font-tthoves);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
  }

  p {
    color: #c1c1cf;
    font-family: var(--font-tthoves);
    white-space: pre-wrap;
    font-size: 12px;
    font-style: normal;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    font-weight: 400;
    line-height: 110%;
  }
}

.bonusConBlock {
  display: flex;
  gap: 4px;
}
