.container {
  border-radius: 120px;
  gap: 16px;
  display: flex;
  padding: 4px 4px 4px 14px;
  align-items: center;
  justify-content: center;
  .text {
    font-family: var(--font-ttsupermolotneue);
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #939bc2;
    white-space: nowrap;
    span {
      font-family: var(--font-ttsupermolotneue);
      font-weight: 600;
      letter-spacing: 0.04em;
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
  .type_login {
    display: flex;
    gap: 4px;
    button {
      display: flex;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      border: none;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      @media (max-width: 1100px) {
        width: 40px;
        height: 40px;
      }
    }
  }
}
