.promoBlock {
  width: 100%;
  height: 56px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image:
    image-set(
      url("../../../assets/images/header-promo/540.webp") 1x,
      url("../../../assets/images/header-promo/540@2x.webp") 2x
    );

  @media screen and (width >= 540px) {
    background-position: 50% 50%, 100% 50%, 0 50%, 50% 50%;
    background-repeat: no-repeat, no-repeat, no-repeat , no-repeat ;
    background-size: cover, contain, contain, cover;
    background-image:
      image-set(
        url("../../../assets/images/header-promo/mobile-main-content.webp") 1x,
        url("../../../assets/images/header-promo/mobile-main-content@2x.webp") 2x
      ),
      image-set(
        url("../../../assets/images/header-promo/r_small.webp") 1x,
        url("../../../assets/images/header-promo/r_small@2x.webp") 2x
      ),
      image-set(
        url("../../../assets/images/header-promo/l_small.webp") 1x,
        url("../../../assets/images/header-promo/l_small@2x.webp") 2x
      ),
      url("../../../assets/images/header-promo/bg.webp");
  }

  @media screen and (width >= 720px) {
    background-size: contain, contain, contain, cover;
    background-image:
      image-set(
        url("../../../assets/images/header-promo/main-content.webp") 1x,
        url("../../../assets/images/header-promo/main-content@2x.webp") 2x
      ),
      image-set(
        url("../../../assets/images/header-promo/r.webp") 1x,
        url("../../../assets/images/header-promo/r@2x.webp") 2x
      ),
      image-set(
        url("../../../assets/images/header-promo/l.webp") 1x,
        url("../../../assets/images/header-promo/l@2x.webp") 2x
      ),
      url("../../../assets/images/header-promo/bg.webp");
  }

  @media screen and (width >= 1200px) {
    height: 80px;
    background-image:
      image-set(
        url("../../../assets/images/header-promo/main-content.webp") 1x,
        url("../../../assets/images/header-promo/main-content@2x.webp") 2x
      ),
      image-set(
        url("../../../assets/images/header-promo/r_big.webp") 1x,
        url("../../../assets/images/header-promo/r_big@2x.webp") 2x
      ),
      image-set(
        url("../../../assets/images/header-promo/l_big.webp") 1x,
        url("../../../assets/images/header-promo/l_big@2x.webp") 2x
      ),
      url("../../../assets/images/header-promo/bg.webp");
  }
}