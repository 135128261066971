.container,
.header_container {
  position: relative;
  cursor: pointer;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  background-color: #939bc2;
  &:hover {
    transform: scale(1.1);
  }
}
.header_container {
  width: 34px;
  height: 34px;
  background-color: rgba(255, 255, 255, 0.32);
}
