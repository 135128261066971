.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 2px;
  border-radius: 120px;
  min-height: 48px;
  width: 100%;
}
.text {
  font-family: var(--font-ttsupermolotneue);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.default {
  @extend .button;
  @extend .text;
  background: var(--primary-color);
  color: #0c1027;
  border: none;
  box-shadow: 0 0 0 4px rgba(211, 248, 90, 0.16);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; 
  letter-spacing: 0.56px;
  font-family: var(--font-ttsupermolotneue);

  &:disabled {
    color: #0c1027;
    opacity: 0.56;
    cursor: auto;
  }

  &:hover:not(:disabled) {
    background: #dcf97b;
    box-shadow: 0 0 0 4px rgba(211, 248, 90, 0.32);
    border: none;
  }
}
