@use '../../styles/utils' as utils;

.avatarBack_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #191f40;
  border-radius: 100%;
  height: 60px;
  min-width: 60px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease;
  border: 4px solid #191f40;
}

.avatarBack_container:hover {
  transform: scale(1.05);
}

.name_container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.userName {
  color: var(--primary-color);

  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
  transition: opacity 200ms ease;

  @include utils.for-size(phone-only) {
    max-width: 200px;
  }
}

.userName:hover {
  opacity: 0.5;
}

.icon {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.icon:hover {
  transform: scale(1.15);
}

.money_container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.money {
  color: white;
  font-size: 18px;
  font-weight: 700;
}
