.container {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 164px;
  height: 92px;

  .container_line {
    border-radius: 6px;
    background-size: 1px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat;
  }

  .container_line1 {
    @extend .container_line;
    position: absolute;
    opacity: 0.16;
  }

  .container_line2 {
    @extend .container_line;
    overflow: hidden;
    position: relative;
    height: 92px;
  }
}

.main_live_item_front {
  position: relative;
  height: 100%;
  transition: -webkit-transform 0.5s cubic-bezier(0.28, 0.67, 0.04, 1);
  transition: transform 0.5s cubic-bezier(0.28, 0.67, 0.04, 1);
  transition: transform 0.5s cubic-bezier(0.28, 0.67, 0.04, 1), -webkit-transform 0.5s cubic-bezier(0.28, 0.67, 0.04, 1);

  .main_live_item_front_img {
    position: absolute;
    right: 8px;
    width: 106px;
    height: 75px;
    span img{
      object-fit: cover;
    }
  }
  
  .main_live_item_front_text {
    position: absolute;
    bottom: 8px;
    left: 8px;
    max-width: 85%;

    .main_live_item_front_text_weaponName {
      font-family: var(--font-arame);
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: #FFFFFF;
      opacity: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .main_live_item_front_text_skinsName {
      font-family: var(--font-arame);
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #FFFFFF;
      overflow: hidden;
      text-transform: uppercase;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.main_live_item_back {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
  left: 0;
  transition: opacity 0.5s ease, visibility 0.5s ease, -webkit-transform 0.5s cubic-bezier(0.28, 0.67, 0.04, 1);
  transition: transform 0.5s cubic-bezier(0.28, 0.67, 0.04, 1),
    opacity 0.5s ease, visibility 0.5s ease;
  transition: transform 0.5s cubic-bezier(0.28, 0.67, 0.04, 1),
    opacity 0.5s ease, visibility 0.5s ease, -webkit-transform 0.5s cubic-bezier(0.28, 0.67, 0.04, 1);

  .main_live_item_back_case {
    position: absolute;
    top: 14px;
    left: 14px;
    width: 60px;
    height: 50px;
  }

  .main_live_item_back_userProfile {
    position: absolute;
    display: flex;
    bottom: 10px;
    left: 14px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 80%;
    max-width: 80%;

    .main_live_item_back_userProfile_text {
      font-family: var(--font-arame);
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.2;
      color: #FFFFFF;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .main_live_item_back_userProfile_avatar {
      border-radius: 50%;
    }
  }
}

.container:hover .main_live_item_back,
.container:hover .main_live_item_front {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

@media (max-width: 599px) {
  .container:hover .main_live_item_back,
  .container:hover .main_live_item_front {
    -webkit-transform: none;
    transform: none;
  }
}

