.container {
  display: flex;
  gap: 8px;
  user-select: none;
  padding-top: 2px;
  padding-bottom: 2px;
  margin: 8px;
  @media (max-width: 1100px) {
    padding-left: 12px;
  }

  .container_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    .icon:not(.no-animation):hover {
      border: 2px solid rgb(25, 31, 64);
    }

    .icon:hover {
      transition: none;
    }

    @media (max-width: 1440px) {
      display: none;
    }
  }
}
.item {
  position: absolute;
  top: 0;
  left: 0;
  flex-shrink: 0;
  width: 164px;
  height: 100%;
  -webkit-transform: translateX(calc((100% + 8px) * var(--position) + 0px));
  transform: translateX(calc((100% + 8px) * var(--position) + 0px));
}

.itemDone {
  position: absolute;
  top: 0;
  left: 0;
  flex-shrink: 0;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition:
    transform 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
  width: 164px;
  height: 100%;
  -webkit-transform: translateX(calc((100% + 8px) * var(--position) + 0px));
  transform: translateX(calc((100% + 8px) * var(--position) + 0px));
}

.itemEnter {
  -webkit-transform: translateX(
                  calc(
                          (100% + 8px) * var(--position) - (100% + 0px) * var(--new-elements-count)
                  )
  );
  transform: translateX(
                  calc(
                          (100% + 8px) * var(--position) - (100% + 0px) * var(--new-elements-count)
                  )
  );
}

.itemEnterActive {
  -webkit-transform: translateX(calc((100% + 8px) * var(--position) + 0px));
  transform: translateX(calc((100% + 8px) * var(--position) + 0px));

  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition:
    transform 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
}

.itemExit {
  opacity: 0;
}

.scrollWrapper {
  flex-grow: 1;
  overflow: hidden;
}

.scrollSkeleton {
  position: relative;
  height: 104px;
  width: 100%;
  display: flex;
  gap: 8px;
}

.scroll {
  position: relative;
  height: 104px;
  width: 100%;
  display: flex;

  &::after {
    content: '';
    position: absolute;
    right: -4px;
    top: 0;
    height: 100%;
    z-index: 1;
    box-shadow: 0 0 9px 8px rgb(12 16 39);
  }
}

@media (max-width: 1440px) {
  .container_btn {
    display: none !important;
  }
}
