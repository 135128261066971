@import './nprogress.css';

:root {
    --primary-color: #d3f85a;
    --secondary-color: rgba(20, 24, 48);
    --background-color: #0c1027;
    /* для обратной совместимости */
    --font-tthoves: 'Inter', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols';
    --font-ttsupermolotneue: 'Play', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols';
    --font-arame: 'Tektur', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols';
    /* новые шрифты */
    --font-inter: 'Inter', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols';
    --font-play: 'Play', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols';
    --font-tektur: 'Tektur', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols';

}

html,
body {
    padding: 0;
    margin: 0;
    background-color: var(--background-color);
    scrollbar-width: thin;
    scrollbar-color: #808080 transparent;
    height: 100%;
}

html {
    scroll-behavior: smooth;
}

html.normal-scroll {
    scroll-behavior: auto;
}

#__next {
    height: inherit;
}

h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
}

body::-webkit-scrollbar {
    width: 8px;
}

body::-webkit-scrollbar-track {
    background: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: rgba(169, 169, 169, 0.3);
    border-radius: 20px;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-family: var(--font-tthoves);
}

a {
    color: inherit;
    text-decoration: none;
}
