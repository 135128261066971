.promoCon {
  position: relative;
  min-width: 267px;
  display: flex;
  align-items: center;
  color: #9297b2;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-radius: 120px;
  cursor: pointer;
  padding: 4px 4px 4px 16px;
  gap: 8px;

  .promoTxt {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  .promoImg {
    position: absolute;
    left: -25px;
    top: -20px;
    @media (max-width: 1200px) {
      left: -27px;
      top: -23px;
      @media (max-width: 1150px) {
        position: relative;
        left: 0;
        top: 0;
        width: 34px;
        height: 34px;
      }
    }
  }
}
