@import '../MainBlock.module.scss';

.avatar_container {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  gap: 16px;
  position: relative;
  // overflow: hidden;
  width: inherit;
  justify-content: space-between;
}

.logoutButton {
  min-width: 48px;
  height: 48px;
  border-radius: 8px;
  background: #191f40;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  padding: 0;
  border: 0;
}

.buttons {
  display: flex;
  gap: 12px;
}

.hd_coin_container {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  align-items: center;
  background-color: #191f40;
  gap: 12px;
  border: 4px solid var(--primary-color);
  padding: 8px 12px;
  user-select: none;

  img {
    transition: transform 0.3s;
  }

  .coin {
    font-family: var(--font-arame);
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-color);
  }

  // &:hover {
  //   img {
  //     transform: scale(1.1);
  //   }
  // }
}

.btn {
  display: flex;
  padding: 0 0 0 16px;
  border-radius: 16px;
  height: 48px;
  cursor: pointer;
}

.logoutLink {
  display: flex;
  align-items: center;
}

.navlink_container {
  display: flex;
  gap: 40px;
  padding-left: 16px;
  padding-right: 48px;

  & > * {
    z-index: 2;
  }
}
