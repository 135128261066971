@use '../../styles/utils' as util;

.logo {
  height: 40px;
  width: 230px;
  z-index: 2;
  transition: opacity 200ms ease;
}

.adaptiveLogo {
  @extend .logo;
  @media (max-width: 1400px) {
    width: 162px;
    height: 28px;
  }
}

.mobileLogo {
  @extend .logo;
  width: 48px;
  height: 21px;
}

.logo:hover {
  opacity: 0.5;
}
