.circularProgressWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
  
    width: 100%;
    height: 100%;
    max-width: 66px;
    max-height: 66px;
  
    span {
      width: 100% !important;
      height: 100% !important;
      max-width: 66px;
      max-height: 66px;
    }
  
    svg circle {
      stroke-width: 3px !important;
    }
  }
  
  .date_circle_outline {
    position: absolute;
  }
  
  .timerText {
    position: absolute;
    font-family: var(--font-play);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 200% */
    text-transform: uppercase;
    display: flex;
  }

  .small {
    svg{
      width: 24px;
      height: 24px;
    }
  }
  